import $ from "jquery"

const redirectTo = (url) => document.location.href = url + "?return_to=" + encodeURIComponent(document.location.href)

const createRedirectAccessKey = (key, url) => {
  $("<a>")
    .hide()
    .attr({ accesskey: key })
    .click(() => redirectTo(url))
    .appendTo(document.body)
}

$(document).ready(() => {
  createRedirectAccessKey("l", "/login")
  createRedirectAccessKey("o", "/logout")
})
