import $ from 'jquery'

// hide the footer until they ask for it
const $footer = $("#about")
const leftWhenHidden = -$footer.outerWidth()
const leftWhenHovered = leftWhenHidden + 20

const teaseDuration = 100
const toggleDuration = 250

let showingFooter = false

function teaseFooter(){
  if (!showingFooter){
    $footer.animate({ left: leftWhenHovered + "px" }, { duration: teaseDuration })
  }
}

function unteaseFooter(){
  if (!showingFooter){
    $footer.animate({ left: leftWhenHidden + "px" }, { duration: teaseDuration })
  }
}

function toggleFooter(){
  if (showingFooter){
    $footer.animate({ left: leftWhenHidden + "px" }, { easing: "swing", duration: toggleDuration })
    showingFooter = false
  } else {
    $footer.animate({ left: 0 }, { easing: "swing", duration: toggleDuration })
    showingFooter = true
  }
}

// there's some JavaScript in the layout that obscures the footer before page load
// to prevent flickering, so turn that off too
$footer.css({
  left: leftWhenHidden + "px",
  visibility: "visible"
})

$("#info-link")
  .hover(teaseFooter, unteaseFooter)
  .on("click", (e) => {
    e.preventDefault()
    toggleFooter()
  })

// stupid Safari doesn't believe images have height
setTimeout(() => {
  const padding = ($footer.outerHeight() - 18) / 2
  const $hider = $("<div>")
    .attr({ id: "footer-hider" })
    .css({ paddingTop: padding + "px", paddingBottom: padding + "px" })
    .on("click", toggleFooter)               // only clickable when visible anyway
    .html("&larr;")

  $footer.append($hider)
}, 500)
