import $ from 'jquery'

class Footnote {

  constructor(link) {
    this.$link = $(link)

    this.$link.on("click", (e) => {
      e.preventDefault()
      this.show()
    })

    // the <li> containing our footnote
    this.$note = $(link.href.match(/(#.*)/)[1])

    // which footnote are we?
    this.idx = link.href.match(/(\d+)$/)[1]

    // initial position before the "show" animation
    this.start = this.$link.position()

    // sort of a hack to get footnote numbers in there
    this.inner = $("<li>")
      .addClass("footnote-container")
      .html(this.$note.html())
      .find(".returner")
        .attr("title", "Hide this footnote")
        .on("click", (e) => {
          e.preventDefault()
          this.hide("fast")
        })
      .end()

    this.element = $("<ol>")
      .hide()
      .attr({ start: this.idx })
      .addClass("footnote-popup")
      .html(this.inner)
      .appendTo(this.$link.closest("article"))

    // final position after the "show" animation
    this.end = {
      left: -this.element.outerWidth() - 30
    }
  }

  show() {
    this.element
      .css({ left: this.start.left + "px", top: this.start.top + "px", opacity: 0, display: "block" })
      .animate({ left: this.end.left + "px", opacity: 1 }, animationParams(this.placeFootnotes))
  }

  hide() {
    if (this.visible()){
      this.element.fadeOut("fast")
    }
  }

  visible() {
    return this.element.is(":visible")
  }
}

const footnotes = []

// slides any overlapping footnotes out of the way
function placeFootnotes() {
  footnotes.forEach((footnote, idx) => {
    if (idx == 0) return

    if (!footnote.visible()) return

    const prev = footnotes[idx - 1]
    if (!prev.visible()) return

    const target = prev.element.position().top + prev.element.outerHeight()
    if (footnote.element.position().top < target){
      footnote.element.animate({ top: target + "px" }, animationParams(placeFootnotes))
    }
  })
}

function animationParams(callback) {
  return {
    duration: 350,
    easing: "swing",
    complete: placeFootnotes
  }
}

function addFootnote(link) {
  footnotes.push(new Footnote(link))
}

$(document).ready(() => {
  $(".footnotes").hide()

  $("a.footnote").each((_, el) => addFootnote(el))
})
